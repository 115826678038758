<template>
  <div class="relative-position">
    <ProviderList
      ref="providerList"
      v-model="providers"
      :options="$options.webinars"
      :add-button-label="$t('button.integrate')"
      :add-provider-button-label="$t('button.addNewWebinarProvider')"
      :add-title="$t('title.addWebinarProvider')"
      :title="$t('label.webinarProvider')"
      :create-fn="createProvider"
      :update-fn="updateProvider"
      @is-provider-editing="isProviderEditing = $event"
      @remove="removeId = $event"
    />

    <div class="page-hint">
      {{ $t('text.webinarPageHint') }}
    </div>

    <AttentionDialog
      :is-open="showConfirmDialog"
      :title="$t('title.attention')"
      @close="onClose"
    >
      <div class="flex justify-center q-px-lg">
        <div class="text-19-400 text-center q-py-lg q-gutter-y-lg">
          <template v-if="hasConnectedForms">
            <div>
              {{ $t('text.webinarConnectedTo') }}
              <b>{{ connectedFormTitles }}</b>
            </div>
            <div>{{ $t('text.disconnectWebinarToRemoveIt') }}</div>
          </template>
          <div v-else>{{ $t('text.deleteWebinarProvider') }}</div>
        </div>
      </div>

      <template #footer>
        <AppButton
          v-if="hasConnectedForms"
          v-close-popup
          align="between"
          icon-right="close"
          class="col"
          color="white"
          text-color="black"
          :label="$t('button.cancel')"
          outline
        />
        <template v-else>
          <AppButton
            v-close-popup
            class="col-4"
            :label="$t('button.no')"
          />
          <AppButton
            icon-right="fl:trash"
            class="col"
            color="white"
            text-color="black"
            :label="$t('button.delete')"
            :loading="loading.remove"
            outline
            @click="remove"
          />
        </template>
      </template>
    </AttentionDialog>
  </div>
</template>

<script>
import AppButton from 'common/components/buttons/AppButton';
import AttentionDialog from 'common/components/dialogs/AttentionDialog';
import { providerMixin } from 'common/mixins';
import ProviderList from 'common/pages/dashboard/components/ProviderList';
import webinars from 'src/helpers/data/webinars';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'WebinarPage',
  webinars,
  components: {
    AppButton,
    AttentionDialog,
    ProviderList,
  },
  mixins: [providerMixin],
  data() {
    return {
      providerUrl: 'webinars',
    };
  },
  computed: {
    ...mapState('dashboard', {
      providers: 'webinars',
    }),
  },
  methods: {
    ...mapActions('dashboard', {
      createProvider: 'createWebinar',
      fetchProviders: 'fetchWebinars',
      updateProvider: 'updateWebinar',
      removeProvider: 'removeWebinar',
    }),
  },
};
</script>
