import { i18n } from 'common/boot/i18n';
import rules from 'common/helpers/rules';

const createLengthRules = (field, min, max) => [
  (value) => rules.MIN_LENGTH(value, field, min),
  (value) => rules.MAX_LENGTH(value, field, max),
];

export default [
  {
    value: ENUMS.WEBINAR_TYPES.EVER_WEBINAR,
    label: 'EverWebinar',
    fields: [
      {
        titleKey: i18n.t('label.title'),
        name: 'title',
        options: {
          rules: createLengthRules(i18n.t('label.title'), 5, 125),
        },
      },
      {
        titleKey: i18n.t('label.apiKeyProvider'),
        name: 'apiKey',
        options: {
          rules: createLengthRules(i18n.t('label.apiKeyProvider'), 5, 256),
        },
      },
    ],
  },
  {
    value: ENUMS.WEBINAR_TYPES.WEBINAR_JAM,
    label: 'WebinarJam',
    fields: [
      {
        titleKey: i18n.t('label.title'),
        name: 'title',
        options: {},
      },
      {
        titleKey: i18n.t('label.apiKeyProvider'),
        name: 'apiKey',
        options: {},
      },
    ],
  },
  {
    value: ENUMS.WEBINAR_TYPES.WEBINARIS,
    label: 'Webinaris',
    fields: [
      {
        titleKey: i18n.t('label.title'),
        name: 'title',
        options: {},
      },
      {
        titleKey: i18n.t('label.webinarId'),
        name: 'webinarId',
        options: {},
      },
      {
        titleKey: i18n.t('label.password'),
        name: 'apiKey',
        options: {},
      },
    ],
  },
  {
    value: ENUMS.WEBINAR_TYPES.ZOOM,
    label: 'Zoom',
    fields: [
      {
        titleKey: i18n.t('label.title'),
        name: 'title',
        options: {},
      },
      {
        titleKey: i18n.t('label.accountName'),
        name: 'accountName',
        options: {},
      },
    ],
  },
];
